import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import { Helmet } from 'react-helmet'

interface IPageProps {
    data: {
        ghostPage: IGhostPage
    },
    location: string
}

export default function Page({ data, location }: IPageProps) {
    const page = data.ghostPage

    return (
        <Layout>
            <Helmet title={data.ghostPage.title} />
            <article className='prose prose-slate dark:prose-invert mx-auto max-w-4xl'>
                <h1 className='text-slate-800 dark:text-slate-300 font-bold'>{page.title}</h1>

                <section
                    className='bg-white dark:bg-slate-900 dark:border dark:border-slate-700 px-8 py-4 rounded-xl'
                    dangerouslySetInnerHTML={{ __html: page.html }} />
            </article>
        </Layout>
    )
}


export const postQuery = graphql`
    query($slug: String!) {
        ghostPage(slug: { eq: $slug }) {
            id
            title
            html
        }
    }
`